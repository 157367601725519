<template>
  <b-overlay rounded="sm" :show="isLoading">
    <validation-observer ref="validationObserver" v-slot="{ handleSubmit }">
      <div class="form">
        <div class="card card-custom">
          <div class="card-header border-0 pt-6 pb-0">
            <div class="card-title align-items-start flex-column">
              <h3 class="card-label font-weight-bolder">
                {{ $t("Personal information") }}
              </h3>
              <span class="text-muted font-weight-bold font-size-sm mt-1">
                {{ $t("Update your personal information") }}
              </span>
            </div>
            <div class="card-toolbar">
              <button class="btn btn-success mr-2" :class="{ 'spinner spinner-light spinner-right': isSaving }"
                @click.prevent="handleSubmit(save)">

                {{ $t("Save Changes") }}
              </button>
            </div>
          </div>
          <div class="card-body">
            <validation-provider v-slot="{ errors }" :name="$t('Avatar')" vid="avatar_tus" tag="div">
              <avatar-input :current-avatar="physicalPerson.avatar" horizontal :error-messages="errors"
                :label="$t('Avatar')" @change="tusId => (form.avatar_tus = tusId)"></avatar-input>
            </validation-provider>

            <validation-provider v-slot="{ errors }" :name="$t('First name')" vid="name" rules="required|max:30"
              tag="div">
              <euro-input v-model="form.name" :label="`${$t('First name')}*`" horizontal
                :error-messages="errors"></euro-input>
            </validation-provider>

            <validation-provider v-slot="{ errors }" :name="$t('Last name')" vid="surname" rules="required|max:30"
              tag="div">
              <euro-input v-model="form.surname" :label="`${$t('Last name')}*`" horizontal
                :error-messages="errors"></euro-input>
            </validation-provider>

            <validation-provider v-slot="{ errors }" :name="$t('Gender')" vid="gender" rules="required" tag="div">
              <euro-radio v-model="form.gender" :options="GENDERS" :label="`${$t('Gender')}*`" horizontal
                :error-messages="errors" size="lg"></euro-radio>
            </validation-provider>

            <validation-provider v-slot="{ errors }" :name="$t('Place of birth')" vid="place_birth" tag="div">
              <euro-place-input v-model="form.place_birth" :label="`${$t('Place of birth')}`" horizontal
                :error-messages="errors"></euro-place-input>
            </validation-provider>

            <validation-provider v-slot="{ errors }" :name="$t('Date of birth')" vid="date_birth" tag="div">
              <date-picker v-model="form.date_birth" :overrides="fromDateOverrides" horizontal :error-messages="errors"
                :label="`${$t('Date of birth')}`">
              </date-picker>
            </validation-provider>

            <validation-provider v-slot="{ errors }" :name="$t('Citizenships')" vid="citizenship_set" tag="div">
              <euro-select v-model="form.citizenship_set" :label="$t('Citizenships')" :options="nationsSelectable"
                horizontal multiple searchable :error-messages="errors"></euro-select>
            </validation-provider>
            <validation-provider v-slot="{ errors }" :name="$t('Marital Status')" vid="marital_status" tag="div">
              <euro-select v-model="form.marital_status" :label="$t('Marital Status')" :options="MARITAL_STATUS"
                horizontal searchable :error-messages="errors"></euro-select>
            </validation-provider>
          </div>
        </div>
      </div>
    </validation-observer>
  </b-overlay>
</template>

<script>
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import moment from "moment";
import datePicker from "@/view/components/datePicker/datePicker.vue";
import PhysicalPersonDetailService from "@/core/services/physical-person/physical-person-detail.service";
import { getValidationErrors, successToast } from "@/core/helpers";
import { mapState } from "vuex";
import icons from "@/core/config/icons.js";
import { backendErrorSwal, unsavedChangesSwal } from "@/core/helpers/swal";

import _ from "lodash";
export default {
  components: {
    datePicker,
  },
  props: {
    physicalPerson: {
      type: Object,
      required: true,
    },
  },

  data() {
    return {
      icons,
      apiErrors: null,
      isSaving: false,
      GENDERS: [],
      MARITAL_STATUS: [],
      form: {
        name: null,
        surname: null,
        date_birth: "",
        place_birth: null,
        gender: null,
        marital_status: null,
        citizenship_set: [],
        physicalpersonwebsite_set: null,
      },
      originalData: null,
      isLoading: false,
    };
  },

  computed: {
    ...mapState("constants", ["countries"]),

    nationsSelectable() {
      return this.countries.map(el => ({ text: el.name, value: el.iso }));
    },

    fromDateOverrides() {
      return this.form.date_birth ? { maxDate: moment() } : null;
    },
  },

  watch: {
    apiErrors: {
      deep: true,
      handler(val) {
        const formObs = this.$refs.validationObserver;
        const newErrors = getValidationErrors(val, this.form);

        formObs.setErrors({ ...newErrors });
      },
    },
  },

  async created() {
    this.isLoading = true;
    try {
      const [gendersRes, maritalStatusRes] = await Promise.all([
        PhysicalPersonDetailService.getGenders(),
        PhysicalPersonDetailService.getMaritalStatus()
      ]);

      this.GENDERS = gendersRes.map(el => ({ value: String(el.value), text: el.display_name }));
      this.MARITAL_STATUS = maritalStatusRes.map(el => ({ value: String(el.value), text: el.display_name }));

    } catch (err) {
      console.error(err);
      backendErrorSwal(err?.response?.data?.detail, err);
    }
    this.fillForm();
    this.isLoading = false;
  },

  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [
      { title: this.$t("Physical person"), route: { name: "manage-physical-person" } },
      { title: this.$t("Personal Informations") },
    ]);
  },

  methods: {
    async save() {
      this.isSaving = true;
      const body = {
        ...this.form,
        citizenship_set: this.form.citizenship_set.map(country => {
          return this.physicalPerson.citizenship_set.find(cs => cs.country === country) ?? { country };
        }),
        physicalpersonwebsite_set: [],
      };
      try {
        await PhysicalPersonDetailService.update(this.physicalPerson.id, body);
        this.originalData = _.cloneDeep(this.form);
        successToast(this.$t("Personal informations saved"));
        this.$emit("updated");
      } catch (err) {
        console.log(err?.response?.status);
        if (err?.response?.status === 400) {
          this.apiErrors = err.response.data;
        } else {
          backendErrorSwal(err?.response?.data?.detail ?? this.$t("Failed to save personal informations"));
        }
      } finally {
        this.isSaving = false;
      }
    },

    fillForm() {
      this.form = {
        name: this.physicalPerson?.name,
        surname: this.physicalPerson?.surname,
        date_birth: this.physicalPerson?.date_birth,
        place_birth: this.physicalPerson?.place_birth,
        gender: this.physicalPerson?.gender,
        marital_status: this.physicalPerson?.marital_status,
        citizenship_set: [...this.physicalPerson.citizenship_set.map(c => c.country)],
        physicalpersonwebsite_set: this.physicalPerson.physicalpersonwebsite_set[0]?.website || "",
      };

      this.originalData = _.cloneDeep(this.form);
    },

    cancel() {
      this.fillForm();
    },
  },

  async beforeRouteLeave(__, ___, next) {
    if (!_.isEqual(this.form, this.originalData)) {
      const res = await unsavedChangesSwal();
      if (res.isConfirmed) await this.save();
      if (res.isDismissed) return;
    }
    next();
  },
};
</script>
