var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('b-overlay', {
    attrs: {
      "rounded": "sm",
      "show": _vm.isLoading
    }
  }, [_c('validation-observer', {
    ref: "validationObserver",
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref) {
        var handleSubmit = _ref.handleSubmit;
        return [_c('div', {
          staticClass: "form"
        }, [_c('div', {
          staticClass: "card card-custom"
        }, [_c('div', {
          staticClass: "card-header border-0 pt-6 pb-0"
        }, [_c('div', {
          staticClass: "card-title align-items-start flex-column"
        }, [_c('h3', {
          staticClass: "card-label font-weight-bolder"
        }, [_vm._v(" " + _vm._s(_vm.$t("Personal information")) + " ")]), _c('span', {
          staticClass: "text-muted font-weight-bold font-size-sm mt-1"
        }, [_vm._v(" " + _vm._s(_vm.$t("Update your personal information")) + " ")])]), _c('div', {
          staticClass: "card-toolbar"
        }, [_c('button', {
          staticClass: "btn btn-success mr-2",
          class: {
            'spinner spinner-light spinner-right': _vm.isSaving
          },
          on: {
            "click": function click($event) {
              $event.preventDefault();
              return handleSubmit(_vm.save);
            }
          }
        }, [_vm._v(" " + _vm._s(_vm.$t("Save Changes")) + " ")])])]), _c('div', {
          staticClass: "card-body"
        }, [_c('validation-provider', {
          attrs: {
            "name": _vm.$t('Avatar'),
            "vid": "avatar_tus",
            "tag": "div"
          },
          scopedSlots: _vm._u([{
            key: "default",
            fn: function fn(_ref2) {
              var errors = _ref2.errors;
              return [_c('avatar-input', {
                attrs: {
                  "current-avatar": _vm.physicalPerson.avatar,
                  "horizontal": "",
                  "error-messages": errors,
                  "label": _vm.$t('Avatar')
                },
                on: {
                  "change": function change(tusId) {
                    return _vm.form.avatar_tus = tusId;
                  }
                }
              })];
            }
          }], null, true)
        }), _c('validation-provider', {
          attrs: {
            "name": _vm.$t('First name'),
            "vid": "name",
            "rules": "required|max:30",
            "tag": "div"
          },
          scopedSlots: _vm._u([{
            key: "default",
            fn: function fn(_ref3) {
              var errors = _ref3.errors;
              return [_c('euro-input', {
                attrs: {
                  "label": "".concat(_vm.$t('First name'), "*"),
                  "horizontal": "",
                  "error-messages": errors
                },
                model: {
                  value: _vm.form.name,
                  callback: function callback($$v) {
                    _vm.$set(_vm.form, "name", $$v);
                  },
                  expression: "form.name"
                }
              })];
            }
          }], null, true)
        }), _c('validation-provider', {
          attrs: {
            "name": _vm.$t('Last name'),
            "vid": "surname",
            "rules": "required|max:30",
            "tag": "div"
          },
          scopedSlots: _vm._u([{
            key: "default",
            fn: function fn(_ref4) {
              var errors = _ref4.errors;
              return [_c('euro-input', {
                attrs: {
                  "label": "".concat(_vm.$t('Last name'), "*"),
                  "horizontal": "",
                  "error-messages": errors
                },
                model: {
                  value: _vm.form.surname,
                  callback: function callback($$v) {
                    _vm.$set(_vm.form, "surname", $$v);
                  },
                  expression: "form.surname"
                }
              })];
            }
          }], null, true)
        }), _c('validation-provider', {
          attrs: {
            "name": _vm.$t('Gender'),
            "vid": "gender",
            "rules": "required",
            "tag": "div"
          },
          scopedSlots: _vm._u([{
            key: "default",
            fn: function fn(_ref5) {
              var errors = _ref5.errors;
              return [_c('euro-radio', {
                attrs: {
                  "options": _vm.GENDERS,
                  "label": "".concat(_vm.$t('Gender'), "*"),
                  "horizontal": "",
                  "error-messages": errors,
                  "size": "lg"
                },
                model: {
                  value: _vm.form.gender,
                  callback: function callback($$v) {
                    _vm.$set(_vm.form, "gender", $$v);
                  },
                  expression: "form.gender"
                }
              })];
            }
          }], null, true)
        }), _c('validation-provider', {
          attrs: {
            "name": _vm.$t('Place of birth'),
            "vid": "place_birth",
            "tag": "div"
          },
          scopedSlots: _vm._u([{
            key: "default",
            fn: function fn(_ref6) {
              var errors = _ref6.errors;
              return [_c('euro-place-input', {
                attrs: {
                  "label": "".concat(_vm.$t('Place of birth')),
                  "horizontal": "",
                  "error-messages": errors
                },
                model: {
                  value: _vm.form.place_birth,
                  callback: function callback($$v) {
                    _vm.$set(_vm.form, "place_birth", $$v);
                  },
                  expression: "form.place_birth"
                }
              })];
            }
          }], null, true)
        }), _c('validation-provider', {
          attrs: {
            "name": _vm.$t('Date of birth'),
            "vid": "date_birth",
            "tag": "div"
          },
          scopedSlots: _vm._u([{
            key: "default",
            fn: function fn(_ref7) {
              var errors = _ref7.errors;
              return [_c('date-picker', {
                attrs: {
                  "overrides": _vm.fromDateOverrides,
                  "horizontal": "",
                  "error-messages": errors,
                  "label": "".concat(_vm.$t('Date of birth'))
                },
                model: {
                  value: _vm.form.date_birth,
                  callback: function callback($$v) {
                    _vm.$set(_vm.form, "date_birth", $$v);
                  },
                  expression: "form.date_birth"
                }
              })];
            }
          }], null, true)
        }), _c('validation-provider', {
          attrs: {
            "name": _vm.$t('Citizenships'),
            "vid": "citizenship_set",
            "tag": "div"
          },
          scopedSlots: _vm._u([{
            key: "default",
            fn: function fn(_ref8) {
              var errors = _ref8.errors;
              return [_c('euro-select', {
                attrs: {
                  "label": _vm.$t('Citizenships'),
                  "options": _vm.nationsSelectable,
                  "horizontal": "",
                  "multiple": "",
                  "searchable": "",
                  "error-messages": errors
                },
                model: {
                  value: _vm.form.citizenship_set,
                  callback: function callback($$v) {
                    _vm.$set(_vm.form, "citizenship_set", $$v);
                  },
                  expression: "form.citizenship_set"
                }
              })];
            }
          }], null, true)
        }), _c('validation-provider', {
          attrs: {
            "name": _vm.$t('Marital Status'),
            "vid": "marital_status",
            "tag": "div"
          },
          scopedSlots: _vm._u([{
            key: "default",
            fn: function fn(_ref9) {
              var errors = _ref9.errors;
              return [_c('euro-select', {
                attrs: {
                  "label": _vm.$t('Marital Status'),
                  "options": _vm.MARITAL_STATUS,
                  "horizontal": "",
                  "searchable": "",
                  "error-messages": errors
                },
                model: {
                  value: _vm.form.marital_status,
                  callback: function callback($$v) {
                    _vm.$set(_vm.form, "marital_status", $$v);
                  },
                  expression: "form.marital_status"
                }
              })];
            }
          }], null, true)
        })], 1)])])];
      }
    }])
  })], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }